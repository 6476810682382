<template>
  <CContainer class="d-flex content-center min-vh-100">
    <CRow>
      <CCol>
        <CCardGroup>
          <CCard class="p-4" style="min-width:300px; max-width:300px; background-color:#3c2468; border:none" 
            text-color="white">
            <CCardBody>
              <CForm>
                <h1 style="color:white; font-size:26px;">Gesto Vapp</h1>
                <p class="text-muted" style="color:white">Portal Usuarios</p>
                <CInput
                  placeholder="Rut Empresa"
                  autocomplete="off"
                  v-model="usuario.rutEmpresa"
                  :is-valid="rutValidado"
                  @keyup="formatearIngresoRut"
                  @blur="validarRut"
                  @keyup.enter.native="iniciarSesion"
                ></CInput>
                <!--@blur="validarRut"-->
                <CInput
                  placeholder="Usuario"
                  autocomplete="off"
                  v-model="usuario.username"
                  @keyup.enter.native="iniciarSesion"
                ></CInput>
                <CInput
                  placeholder="Contraseña"
                  type="password"
                  autocomplete="off"
                  v-model="usuario.passw"
                  @keyup.enter.native="iniciarSesion"></CInput>
                <CRow>
                  <CCol col="12" class="text-right mb-2"><a href="#" @click="goReestablecerPsw">He olvidado la contraseña</a></CCol>
                </CRow>
                <CRow>
                  <CCol col="12" class="text-left">
                    <CAlert style="max-width:250px" closeButton v-show="showMensaje" :color="tipoMensaje">{{mensajeLogin}}</CAlert>
                  </CCol>
                </CRow>
                <CRow v-show="!showMensajeCambioPass && !showMensajeRutNoConfig">
                    <CCol col="12" class="text-rigth" v-show="isLogin">
                    <center>
                      <img src="/img/logo2.png" size="custom-size" :height="40" class="imgr mt-3 mb-3" /> Iniciando Sesión...
                    </center>
                    </CCol>
                  <CCol col="12" class="text-rigth" v-show="!isLogin">
                    <CButton block shape="pill" style="background-color:#0b7689;color:white;" class="px-4" @click="iniciarSesion">Ingresar</CButton>
                  </CCol>
                </CRow>
                <CRow class="mt-2" v-show="showMensajeCambioPass || showMensajeRutNoConfig">
                  <CCol col="12">
                    
                    <CRow v-show="showMensajeCambioPass">
                      <CCol col="10">
                          <CAlert :color="tipoMensaje" style="max-width:230px">{{msjCambiarPass}}</CAlert>
                      </CCol>
                      <CCol col="2">
                        <CButton class="mt-3" style="background-color:#0b7689;color:white;" @click="cambiarContraseña()">Si!</CButton>
                      </CCol>
                    </CRow>
                    <CRow v-show="enviandoSolicitud">
                      <CCol sm="12" md="12" lg="12">
                        <CAlert color="success" style="max-width:300px">{{msjSolicitandoCambio}}</CAlert>
                      </CCol>
                    </CRow>
                    
                    <CRow v-show="showMensajeRutNoConfig">
                      <CCol col="10">
                          <CAlert :color="tipoMensaje" style="max-width:230px">{{msjRutNoConfig}}</CAlert>
                      </CCol>
                      <CCol col="2">
                        <CButton class="mt-3" style="background-color:#0b7689;color:white;" @click="ingresarRut = !ingresarRut">{{(ingresarRut)?'Luego':'Si!'}}</CButton>
                      </CCol>
                    </CRow>
                    
                    <CRow v-show="ingresarRut">
                      <CCol col="12">
                        <CInput
                          :is-valid="rutValidarRegistrar"
                          @blur="validarRutRegistrar"
                          placeholder="Rut Usuario"
                          autocomplete="Rut Usuario"
                          v-model="rutRegistrar"
                        ></CInput>
                      </CCol>
                    </CRow>
                    
                    <CRow>
                      <CCol col="12">
                        <CButton block shape="pill" 
                        :disabled="(ingresarRut && !rutValidarRegistrar) || (enviandoSolicitud && !solicitudEnviada)" 
                        style="background-color:#0b7689;color:white;width: 100%;" @click="loguearUser">Continuar</CButton>
                      </CCol>

                    </CRow>
                  </CCol>


                  <!--
                    <CRow v-show="!enviandoSolicitud">
                      <CCol sm="8" md="8" lg="6">
                        <CButton style="background-color:#0b7689;color:white;" @click="loguearUser">Luego</CButton>
                      </CCol>
                      <CCol sm="4" md="4" lg="6">
                        <CButton style="background-color:#0b7689;color:white;">Si!</CButton>
                      </CCol>
                    </CRow>
                    <CRow v-show="enviandoSolicitud">
                      <CCol sm="12" md="12" lg="12">{{msjRutNoConfig}}</CCol>
                    </CRow>
                  </CCol>
                  <CCol sm="12" md="12" lg="12" v-show="showMensajeCambioPass">
                    <CRow v-show="!enviandoSolicitud">
                      <CCol sm="8" md="8" lg="6">
                        <CButton style="background-color:#0b7689;color:white;" @click="loguearUser">Luego</CButton>
                      </CCol>
                      <CCol sm="4" md="4" lg="6">
                        <CButton style="background-color:#0b7689;color:white;" @click="cambiarContraseña()">Si!</CButton>
                      </CCol>
                    </CRow>
                    <CRow v-show="enviandoSolicitud">
                      <CCol sm="12" md="12" lg="12">{{msjSolicitandoCambio}}</CCol>
                    </CRow>
                  </CCol>
                   <CRow class="mt-2" v-show="showMensajeCambioPass">
                  <CCol sm="12" md="12" lg="12">
                    <CRow>
                      <CAlert :color="tipoMensaje" style="max-width:250px">{{msjCambiarPass}}</CAlert>
                    </CRow>
                  -->
                </CRow>
                
                <hr>
                <CRow>
                    <CCol col="12">
                      <CButton block shape="pill" class="btnPrincipalVapp mt-2" @click="cambioPortal">Ir a portal Trabajador</CButton>
                    </CCol>

                  </CRow>
               
                
              </CForm>
            </CCardBody>
          </CCard>

          <!--<CCard
            color="primary"
            text-color="white"
            class="text-center py-5 d-sm-down-none"
            body-wrapper
          >
            <h1>Sistema de Reportes VAPP</h1>
            <p>Para ingresar utilice su usuario y contraseña otorgado por vapp, en caso de no disponer uno contacte a soporte de vapp al correo soporte@vapp.cl</p>
            <CButton
              color="primary"
              class="active mt-3"
            >
              Register Now!
            </CButton>
          </CCard>-->
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
    import cons from '@/const'
    import axios from "axios"

    export default {
        name: 'Login',
        
        data () {
        return {
            urlBase: cons.port+"://" + cons.ipServer,

            isLogin: false,
            //expiracionCookie: "8h",
            enviandoSolicitud: false,
            solicitudEnviada: false,
            msjSolicitandoCambio: "",

            tipoMensaje: "success",
            showMensaje : false,
            mensajeLogin: "",
            showMensajeCambioPass: false,
            msjCambiarPass: "Su contraseña está por defecto, Le recomendamos cambiar la contraseña por seguridad",
            cambiarPss: false,

            showMensajeRutNoConfig: false,
            msjRutNoConfig: "",
            rutValidarRegistrar: false,

            rutValidado: false,

            usuario: {
            username:"",
            passw:"",
            rutEmpresa: null
            },

            logueoResp: null,
            rutRegistrar: null,
            ingresarRut: false
        }
        },
        beforeMount() {
          
        cons.puertoQa = ("port" in this.$route.query) ? ":"+this.$route.query.port : "";
        this.validarSesion()
        },
        methods: {
            cambioPortal: function(){
                this.$router.push('login_trabajador')///pages/login
            },
            goReestablecerPsw: function(){
                this.$router.push('ReestablecerPsw')
            },
            validarRutRegistrar: function(event){
                var rutCompleto = this.rutRegistrar
                    
                if(rutCompleto == "" || rutCompleto == null){
                this.rutValidarRegistrar = false
                } else{

                rutCompleto = rutCompleto.replace("‐","-");

                if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)){
                    this.rutValidarRegistrar =  false;
                    return
                }

                var tmp = rutCompleto.split('-');
                var digv = tmp[1]; 
                var rut = tmp[0];

                if ( digv == 'K' ) digv = 'k' ;

                this.rutValidarRegistrar = (this.validarDv(rut) == digv )
                }
            },
            registrarRut: function(){
                
                
                var url =  this.urlBase + "/reg_rut_usuario"
                axios({
                url: url,
                method: 'POST',
                "data": {
                    "info": {
                        "rut": this.rutRegistrar,
                        "usuario": this.usuario.username,
                        "idCliente": this.logueoResp.idCliente
                    }
                },
                "headers": {
                    'Authorization': `${this.tokenLogin}`
                },

            }).then((response) => {

            })
            .finally(() => {
            });


            },
            iniciarSesion: function(){
                this.isLogin = true
                

                if(this.usuario.username == null 
                || this.usuario.rutEmpresa == null 
                || this.usuario.username == "" 
                || this.usuario.passw == null 
                || this.usuario.passw == "" 
                || this.usuario.rutEmpresa == ""){
                    this.showMensaje = true
                    this.mensajeLogin = "Ingrese correctamente sus datos"
                    this.tipoMensaje = "danger"
                }else{
                    if(this.rutValidado){
                        this.showMensaje = true
                        this.mensajeLogin = "Ingrese correctamente el rut de la empresa"
                        this.tipoMensaje = "danger"
                    }

                this.showMensaje = false
                var url =  this.urlBase + "/log_in/" + this.usuario.username + "/" + this.usuario.passw + "/" + this.usuario.rutEmpresa.replace(/\./g, '')
                
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        usuario: {
                          username: this.usuario.username,
                          passw: this.usuario.passw,
                          rutEmpresa: this.usuario.rutEmpresa.replace(/\./g, '')
                        }
                    }
                }).then(result => {
                    
                    this.logueoResp = result.data.userLog

                    if(this.logueoResp.rutSinAgregar){
                    this.showMensajeRutNoConfig = true
                    this.msjRutNoConfig = "Su rut no está configurado, Le recomendamos agregarlo, ¿quiere hacerlo?"
                    }

                    if(this.logueoResp.pswDefecto){
                    this.showMensajeCambioPass = true
                    this.msjCambiarPass = "Su contraseña está por defecto, Le recomendamos cambiar la contraseña por seguridad, ¿quiere hacerlo?"

                    } 
                    if (!this.logueoResp.rutSinAgregar && !this.logueoResp.pswDefecto) {
                    this.loguearUser()
                    }
                    this.isLogin = false
                    
                }, error => {
                    this.tipoMensaje = "danger"
                    this.mensajeLogin = error.response.data.mensaje
                    this.showMensaje = true
                    this.isLogin = false
                    
                })
                .finally(() => {
                });
                
                }
                
            },
            loguearUser: function(){
                if(this.ingresarRut)
                    this.registrarRut()

                let cookieUsername = this.$cookie.get('userLogginToken')
                var uslog = this.logueoResp

                if(cookieUsername === null || cookieUsername === ""){

                this.$cookie.set("userLogginToken", JSON.stringify(uslog), { expires: this.logueoResp.fechaExpToken });

                } else {

                if(uslog.tokenUsuario != JSON.parse(cookieUsername).tokenUsuario){
                    this.$cookie.set("userLogginToken", JSON.stringify(uslog), { expires: this.logueoResp.fechaExpToken });
                }

                }

                this.showMensaje = true
                this.mensajeLogin = "Bienvenido " + this.logueoResp.nombreUsuario
                this.tipoMensaje = "success"
                this.$notification.success("Bienvenido " + this.logueoResp.nombreUsuario, {  timer: 10, position:"bottomRight", showCloseIcn:true});
                cons.rutaRedireccion = this.logueoResp.rutaRedireccion
                
                this.$router.push(this.logueoResp.rutaRedireccion);

            },
            
            cambiarContraseña: function(){

                this.enviandoSolicitud = true
                this.msjSolicitandoCambio = "Enviando Solicitud"
                var url = this.urlBase + "/solicitar_cambio_pass/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.logueoResp.tokenUsuario}`
                    }
                }).then(result => {

                this.msjSolicitandoCambio = "Solicitud enviada, revise su correo."
                this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});
                this.solicitudEnviada = true
                    
                }, error => {
                this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
                    
                })
                .finally(() => {
                
                });
            },

            validarSesion: function(){
                let cookieUsername = this.$cookie.get('userLogginToken')

                if(cookieUsername !== null ){
                
                this.$router.push(JSON.parse(cookieUsername).rutaRedireccion);
                }    
            },
            validarRut:function(event){
                var rutCompleto = this.usuario.rutEmpresa.replace(/\./g, '');//replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
                
                if(rutCompleto == "" || rutCompleto == null){
                  this.rutValidado = false
                } else{

                rutCompleto = rutCompleto.replace(/-/g,"-");

                if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)){
                    this.rutValidado =  false;
                    return
                }

                var tmp = rutCompleto.split('-');
                var digv = tmp[1]; 
                var rut = tmp[0];

                if ( digv == 'K' ) digv = 'k' ;
                this.rutValidado = (this.validarDv(rut) == digv )
                }
            },
            validarDv: function(T){
                var M=0,S=1;
                for(;T;T=Math.floor(T/10))
                    S=(S+T%10*(9-M++%6))%11;
                return S?S-1:'k';
            },
            formatearIngresoRut: function(){
                this.usuario.rutEmpresa = this.formatoRut(this.usuario.rutEmpresa)
                this.validarRut()
            },
            formatoRut: function(rut){
                var sRut1 = rut.replace(/\./g,"").replace(/-/g,"");      //contador de para saber cuando insertar el . o la -
                var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
                var sInvertido = ""; //Guarda el resultado final del rut como debe ser
                var sRut = "";
                for(var i=(sRut1.length-1); i>=0; i-- ){
                    sInvertido += sRut1.charAt(i);
                    if (i == sRut1.length - 1 )
                        sInvertido += "-";
                    else if (nPos == 3)
                    {
                        sInvertido += ".";
                        nPos = 0;
                    }
                    nPos++;
                }
                for(var j = sInvertido.length - 1; j>= 0; j-- )
                {
                    if (sInvertido.charAt(sInvertido.length - 1) != ".")
                        sRut += sInvertido.charAt(j);
                    else if (j != sInvertido.length - 1 )
                        sRut += sInvertido.charAt(j);
                }
                //Pasamos al campo el valor formateado
                //rut.value = sRut.toUpperCase();
                return sRut.toUpperCase();
            },
            /*function checkRut(rut) {
              // Despejar Puntos
              var valor = rut.value.replace('.','');
              // Despejar Guión
              valor = valor.replace('-','');
              
              // Aislar Cuerpo y Dígito Verificador
              cuerpo = valor.slice(0,-1);
              dv = valor.slice(-1).toUpperCase();
              
              // Formatear RUN
              rut.value = cuerpo + '-'+ dv
              
              // Si no cumple con el mínimo ej. (n.nnn.nnn)
              if(cuerpo.length < 7) { rut.setCustomValidity("RUT Incompleto"); return false;}
              
              // Calcular Dígito Verificador
              suma = 0;
              multiplo = 2;
              
              // Para cada dígito del Cuerpo
              for(i=1;i<=cuerpo.length;i++) {
              
                  // Obtener su Producto con el Múltiplo Correspondiente
                  index = multiplo * valor.charAt(cuerpo.length - i);
                  
                  // Sumar al Contador General
                  suma = suma + index;
                  
                  // Consolidar Múltiplo dentro del rango [2,7]
                  if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }
            
              }
              
              // Calcular Dígito Verificador en base al Módulo 11
              dvEsperado = 11 - (suma % 11);
              
              // Casos Especiales (0 y K)
              dv = (dv == 'K')?10:dv;
              dv = (dv == 0)?11:dv;
              
              // Validar que el Cuerpo coincide con su Dígito Verificador
              if(dvEsperado != dv) { rut.setCustomValidity("RUT Inválido"); return false; }
              
              // Si todo sale bien, eliminar errores (decretar que es válido)
              rut.setCustomValidity('');
          }*/

        }
    }
</script>

<style scoped>
.imgr{
  -webkit-animation: 3s rotate linear infinite;
  animation: 3s rotate  linear infinite;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
</style>